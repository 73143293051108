import React from "react";
import { Col, Row } from "react-bootstrap";
//  import { CgCKotlin } from "react-icons/all";
import {
  DiJava,
  DiMysql,
  DiGit,
  DiAndroid,
  DiNginx,
  DiDocker,
} from "react-icons/di";
import {
  SiPytorch,
  SiFirebase,
  SiNextdotjs,
  SiKotlin,
  SiSpringboot,
  SiJavascript,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiKotlin />
        <p className="techstack">Kotlin</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJavascript />
        <p className="techstack">Javascript</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <p className="techstack">Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpringboot />
        <p className="techstack">SpringBoot</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAndroid />
        <p className="techstack">Android</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <p className="techstack">MySql</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <p className="techstack">Firebase</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <p className="techstack">Docker</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p className="techstack">Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNginx />
        <p className="techstack">Nginx</p>
      </Col>
    </Row>
  );
}

export default Techstack;
