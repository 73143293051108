import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <Container>
        
        <h1 className="project-heading">Professional Skillset</h1>

        <Techstack />

        <h1 className="project-heading">Tools I use</h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
