import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Typewriter from "typewriter-effect";

import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  const hideContact = new URLSearchParams(window.location.search).get("hide-contact");

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <Typewriter
              options={{
                strings: ["root@root:~$ whoami"],
                autoStart: true,
                loop: true,
                delay: 120,
                deleteSpeed: 40,
              }}
            />

            <p className="home-about-body">
              I started programming at young age and as my interest grew more
              and more, I decided I could do a better job if I'd take the
              responsibility of my education into my own hands; therefore, I
              switched to the "open schooling" system that is similar to
              homeschooling and found much more time to study programming,
              learning other languages and improving myself in other ways. 
              <br />
              <br />
              Soon after my graduation, I was offered a position at Vektora and started working in the Non-SAP Solutions Backend team.
              I have played a critical role in the development of the backend servers, desktop applications, and DevOps. 
              Consequently, I was promoted within 6 months and have been told it is an extremely rare occurrence to be promoted in such a 
             short period of time.
              
              <br />
              <br />

              3 months after my promotion, I started leading the Backend development and assisting the new members of our team.        
              <br />
              <br />

              <br />
              My favorite languages are:
              <i>
                <b className="blue"> Kotlin and Java. </b>
              </i>
              <br />
              <br />
              My field of Interests are: &nbsp;
              <i>
                <b className="blue">Web Server Development </b> and
                <b className="blue"> Android development.</b>
              </i>
              <br />
              <br />
              {/* TODO Add frameworks */}
            </p>
          </Col>
        </Row>
        <Row>
       { hideContact!="true" && 
          <Col md={12} className="home-about-social">
          
            <h1>CONTACT</h1>
            <p>
              mustafakurt.business@gmail.com
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/developerKurt"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
            
                  href="https://www.linkedin.com/in/developer-kurt/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
               
              </li>
            </ul>
          </Col>
}
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
