import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import eagreement from "../../Assets/Projects/eagreement.JPG";
import vcrm from "../../Assets/Projects/vcrm.JPG";
import gamedatabase from "../../Assets/Projects/gamedatabase.png";
import countryselector from "../../Assets/Projects/countryselector.png";
import braintwins from "../../Assets/Projects/braintwins.png";
import ghosky from "../../Assets/Projects/ghosky.png";
import tvshowmanager from "../../Assets/Projects/tvshowmanager.png";
import eagreementdesktop from "../../Assets/Projects/desktop.png";
import defaultIcon from "../../Assets/Projects/default.png";
import { padding } from "@mui/system";

function Projects() {
  return (
    <Container fluid className="project-section" id="projects">
      <Container>
        <h1 className="project-heading">
          <strong className="blue">Portfolio </strong>
        </h1>
        <h5 style={{ color: "white", margin: "30px"}}>
         Some of the projects I have worked on at <strong className="blue">Vektora </strong>
        </h5>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={eagreement}
              isBlog={false}
              title="E-Agreement"
              description="A web-based product with enriched features that aims at helping companies to send each other agreements by continuously integrating and processing their entire accounting ERP data."
              longDescription="E-Agreement is a project that we have developed for one of the largest textile holdings in Turkey.  
              They needed a convenient way to manage their agreement process with the other companies. 
              To make an agreement, they needed to apply a series of accounting/business logic to their accounting report data (which is connected to their ERP system and it is constantly getting updated)
              One of my responsibilities in this project was to fetch and process millions of accounting reports and other data from their system and persist it to our system.
              During this process, I faced many challenges such as improving the performance, persisting the critical accounting information in a robust way, and testing the validity of the provided data.
              "
              keyPoints={[
                "Developed a web server using Spring Boot",
                "Handled the MySQL database migrations using Flyway",
                "Wrote SQL, bash, and batch scripts for database migration and deployment purposes",
                "Refactored the code base, made it scalable, and used some of the best practices & design patterns",
                "Developed a product-independent ERP integration infrastructure",
                "Integrated the project to the internal revenue service's system",
                "Introduced and integrated the error tracking software Rollbar into our project",
                "Wrote unit and integration tests",
              "Configured the Docker Compose for the deployment",
              "Contributed to introducing a fully customizable authorization system where clients can assign their employees to view only the assigned pages",
              "Debugged the server and fixed the reported bugs"]}
        
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={eagreementdesktop}
              isBlog={false}
              title="E-Agreement Desktop Client"
              description="A desktop digital signature application that I have developed and integrated with the E-Agreement project in order to automatically sign the agreements through a websocket connection."
              longDescription="A sub-product of the E-Agreement that I have single-handedly developed which enables our clients to electronically sign their documents/mails either automatically by connecting to our services via a websocket or manually by dragging and dropping their files onto the application. 

              The project is also can be easily updated since it retrieves its config and policy files through our AWS system"
              keyPoints={["Used the JavaFX, SmartCard, and ESYA libraries to develop the project",
              "Developed a web socket server to connect to the website in order to receive and sign the required emails and documents automatically",
              "Added full support for most of the smart card drivers",
              "Added support for the CAdES, PAdES and, XAdES formats",
              "Connected to an Amazon S3 server to keep the smart card config files up to date",
              "Wrote the acceptance tests for creating, receiving, signing and sending the agreements",
              "Took advantage from the MVC, State, Observer, and Singleton patterns",
              "Designed a reactive UI where certain elements change their color depending on the connection, error or signing states",
              "Wrote unit and integration tests",
              "Signed and packaged the application to an executable"]}
            />
          </Col>

      
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vcrm}
              isBlog={false}
              title="Project Offer Pricing"
              description="A web-based product that helps one of our clients to manage their factories' complex project requests, approval and authorization system. "
              longDescription="The project aims to make it easier for our client to get production or design requests from their clients.  It helps them to track the progression of each request by allowing the responsible employees or departments to view the requests that are waiting to be evaluated (to be approved or rejected).  
              As the project gets approved by a department, the details are then forwarded to the next responsible department. 
              For example, the feasibility of the project is evaluated by a certain group of people and the marketing parts are evaluated by a separate group. Therefore, we have developed a group permission system, consequently, only the responsible people are able to see the sensitive business information.
              One of my responsibilities was to develop an ABAC authorization system where the client would have the ability to authorize certain user groups depending on the conditions the clients sets for out Java classes' properties and the action (READ, WRITE, APPROVE etc). The properties that would make sense to be presented to clients have been annotated with an annotation along with its display name. Which has made it easier for the clients to use."
              keyPoints={["An attribute-based access control system using Casbin for our client's permission management needs",
              "Designed and implemented the SQL data structure, header item relationships and transaction management",
              "Created a testing structure to increase our speed writing unit and integration tests"]}
            />
          </Col>

          <h5 style={{ color: "white", margin: "30px"}}>
         Some of the projects I have worked on as a <strong className="blue">Freelancer</strong>
        </h5>
          

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={defaultIcon}
              isBlog={false}
              title="Forex Analytics"
              description="Improved a popular Forex analytics solution that uses AKKA, Kubernetes, and Azure"
              longDescription="This project involved enhancing a widely-used Forex analytics solution that utilizes AKKA and Kubernetes. 
              As part of the project, I was responsible for debugging and resolving multiple issues in the Java AKKA microservices,
              implementing the requested improvements, managing Kubernetes configurations, and setting up the Azure pipeline configurations. 
              Also, to ensure the stability and performance of the servers, I performed load testing using Apache JMeter. 
              "
              keyPoints={[
                "Improved the AKKA microservices",
                "Configured the Kubernetes and Azure pipeline",
                "Performed load tests using Apache JMeter"
              ]}
            />
          </Col>

      
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={defaultIcon}
              isBlog={false}
              title="B-Bot"
              description="A Java bot that can bypass CloudFlare, Captcha and send GraphQL requests to automate one of my client's routine work."
              longDescription="A solution I have developed to automate my client's routine tasks with a website that uses GraphQL. 
              Since the web server has taken many security measures to prevent attacks or bots, I had to find my way around Auth0, Captcha, and Cloudflare protocols.
              Additionally, the Graphql retrospective query was disabled, therefore I have recreated the models, queries and mutations by tracking the network requests and 
              checking the decompiled Android source code (it didn't violate the application's license) 
              "
              keyPoints={[
                "Bypassing the bot detection software",
                "Recreating GraphQL queries and mutations by tracking the requests.",
                "Selenium API",
                "Reverse engineering an Android app"
              ]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={defaultIcon}
              isBlog={false}
              title="BlobStorage"
              description="A SpringBoot Webflux server that stores large BLOB data and fetches them efficiently using the DynamoDB NoSQL database."
              longDescription="A fast and efficient BlobStorage solution I have developed for my client's specific requirements for speed and scalability.

              In order to meet the requirements, I have used DynamoDB as the database and integrated it with the SpringBoot Webflux server. By this way, I have leveraged the performance and scalability features of both technologies to provide fast and efficient access to BLOB data.
              I have used both REST API and GraphQL.
              "
              keyPoints={[
                "Used AWS DynamoDB",
                "Used Terraform to configure the DynamoDb",
                "Developed reactive services by using Spring WebFlux",
                "Provided both GraphQL and REST API endpoints",
                "Achieved 90% code coverage by writing unit and integration tests"
              ]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={defaultIcon}
              isBlog={false}
              title="Media Fetcher"
              description="A configurable Kotlin console application that can fetch any m3u file and format or translate the media's name or send them to other servers."
              longDescription="A console application that downloads media from a m3u file and can be configured to do translations, formating, media transfers to an another server."
              keyPoints={[
                "Translation support for the downloaded media to/from any language using the Google Translate's API",
                "File transfer support. It can start transferring  the files to multiple other servers after downloading a specified amount of media",
                "Path formatting support. It can detect the season, or episode information from the URL and can store the downloaded media under well-structured language, series and episode folders",
                "File naming support. Aside from the translations, it can convert the unicode characters into their ASCII representations."
              ]}
            />
          </Col>


          <h5 style={{ color: "white", margin: "30px"}}>
         Some of the <strong className="blue">personal projects</strong> I have worked on 
        </h5>
          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gamedatabase}
              isBlog={false}
              title="Game Database"
              description="An open-source Android application that retrieves a list of games and their details from a remote server and caches them depending on the repository's configuration. It is written entirely in Kotlin using the best practices."
              longDescription="An open-source Android application that retrieves a list of games and their details from a remote server and caches them depending on the repository's configuration. It is written entirely in Kotlin using the best practices."
              keyPoints={["Fragments' states are always preserved and their backstack works as expected (it uses the navigation component with the BottomNavigationView)",
              "Coroutines are used as the preferred way of multithreading",
              "Day/Night theme is supported",
              "Well-tested",
              "Data&view binding",
              "Room database",
              "REST API with Retrofit"]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ghosky}
              isBlog={false}
              title="Ghosky"
              description="An Android game I have designed, drawn and developed in Kotlin from scratch along with its 2D game engine using the MVVM pattern. "
              longDescription="An Android game I have designed, drawn and developed in Kotlin from scratch along with its 2D game engine using the MVVM pattern.              "
              keyPoints={["Using advanced Kotlin features such as coroutines, Flow, inline functions, and lazy initializers etc.",
              "Doing performance optimization with JVM languages (Kotlin, Java)",
              "Using advanced Kotlin features such as coroutines, Flow, inline functions, and lazy initializers etc.",
              "Safely multithreading by using synchronized blocks, volatile fields and locks",
              "Using various design patterns such as Object Pooling, MVVM, Singleton, Observer etc.",
              "Detecting the performance issues or memory leaks by analyzing CPU/memory data of Android profiler",
              "Doing advanced version control management operations",
              "Creating OpenGL ES, vertex and fragment shaders",
              "Using MotionLayout, Navigation component, DataStore, data and view binding, and other relatively new JetPack libraries"]}         
         />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={countryselector}
              isBlog={false}
              title="Country Selector"
              description="An open-source Android CRUD application."
              longDescription="An open-source Android CRUD application written in Java."
              keyPoints={["Dependency Injection with Dagger 2",
              "REST API",
              "UI and Unit testing"]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={braintwins}
              isBlog={false}
              title="BrainTwins"
              description="An Android social media application that I have developed which helps people to find like-minded individuals."
              longDescription="An Android social media application that allows people to create or choose tags and get matched with the other people who also have them. The application has been developed and released successfully but due to the problems with the funding and marketing, it has been put on hold."
              keyPoints={["Reactive programming",
              "Usage, configuration and security of the databases like Firebase and Elasticsearch",
              "Firebase platform. (Realtime database, storage, hosting, cloud functions, and Crashlytics)",
              "Adaptive UI/UX design on Android",
              "~15,500 lines of Java and ~1200 lines of NodeJS cloud functions code",
              "Hands-on experience with the Spring Boot and Android SDK"]}
            />
          </Col>
      

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tvshowmanager}
              isBlog={false}
              title="Tv Show Mananger"
              description="An open-source basic Android CRUD application made with GraphQL that can retrieve a list of tv shows with pagination or add a new tv show."
              longDescription="An open-source basic Android CRUD application made with GraphQL that can retrieve a list of tv shows with pagination or add a new tv show."
              keyPoints={["GraphQL",
              "RXJava",
              "Day/Night theme is supported"]}
            />
          </Col>


        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
