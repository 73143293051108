import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiJenkins,
  SiIntellijidea,
  SiAndroidstudio,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroidstudio />
        <p className="techstack">Android Studio</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <p className="techstack">Visual Studio Code</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiIntellijidea />
        <p className="techstack">IntelliJ IDEA</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJenkins />
        <p className="techstack">Jenkins</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
        <p className="techstack">Linux</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
