import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import profile from "../../Assets/profile.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import TypeTitles from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 20 }} className="heading">
              Hi!{" "}
            
              </h1>

              <h1 className="heading-name">
                I am
                <strong className="main-name"> Mustafa Kurt</strong>
              </h1>

              <p style={{ paddingBottom: 20 }} className="heading-content">
              My friends usually call me Kurt.
              <br></br>              <br></br>

              I am a young self-taught programming enthusiast who is 
              <br></br>
              specialized in Java and Kotlin.{" "}  
            
              </p>
              {/* <div style={{ padding: 50, textAlign: "left" }}>
                <TypeTitles />
              </div> */}
            </Col>

            <Col md={5} style={{ opacity: 0.8, paddingBottom: 80 }}>
            <span className="wave" role="img" aria-labelledby="wave">
              <img
                src={profile}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "350px" }}
              />
            </span>

              
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
