import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import React, { useEffect, useState } from "react";

function ProjectCards(props) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleDetails = () => setShow(true);

  return (
<>


    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button variant="primary" onClick={handleDetails} >
          
          {props.isBlog ? "View Blog" : "View Details"}
        </Button>
      </Card.Body>
    </Card>
    
    <Modal className="modal custom"
    show={show}
    dialogClassName="custom"
    onHide={handleClose}
    centered
    size="lg"
    >

        <Modal.Header  >
          <Modal.Title className="text-center" >{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
          {props.longDescription}
          
          <div style={{ whiteSpace: 'pre-wrap' }}>  </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>  </div>

         <b> Key Points:</b>
         <div style={{ whiteSpace: 'pre-wrap' }}>  </div>


         <ul>
         <div>  {props.keyPoints?.map(it =>    <li> {it }</li>)}  </div>
        </ul> 
          {/* <div> {props.keyPoints}  </div> */}

         

          
        </Modal.Body>
       
      </Modal>
    </>



  );
}
export default ProjectCards;
